const initialState = {
  group: {}
}

export default function(state = initialState, action) {
  switch(action.type){
    case "GROUPS_DETAILS":
      return { ...state, group: action.payload }
    default: {
      return state
    }
  }
}
  