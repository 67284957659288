// ** Redux Imports
import { combineReducers } from 'redux'

// ** Reducers Imports
import auth from './reducers/auth'
import navbar from './reducers/navbar'
import layout from './reducers/layout'
import group from './reducers/groups'
import groupCurrent from './reducers/groupCurrent'
import simulator from './reducers/simulators'
import lesson from './reducers/lessons'
import page from './reducers/pages'
import chapters from './reducers/chapters'
import promocodes from './reducers/promocodes'
import characters from './reducers/characters'
import theory from './reducers/theory'
import product from './reducers/product'
import comment_requests from './reducers/comment_requests'
import statistics from './reducers/statistics'
import tags from './reducers/tags'
import banners from './reducers/banners'

const rootReducer = combineReducers({
  auth,
  navbar,
  group,
  simulator,
  page,
  layout, 
  lesson, 
  chapters, 
  promocodes,
  characters,
  comment_requests,
  theory,
  product,
  statistics,
  groupCurrent,
  tags,
  banners
})

export default rootReducer
