const initialState = {
  tags: []
}
  
export default function(state = initialState, action) {
  switch(action.type){
    case "TAGS_INDEX":
      return { ...state, tags: action.payload }
    default: {
          return state
      }
  }
}
  